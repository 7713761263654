import * as React from 'react';
import { Fragment, useState, useEffect } from 'react';
import {NavLink} from 'react-router-dom';
import { useLocation} from "react-router";
import { HashLink as Link } from 'react-router-hash-link';

import style from './style.scss';


export default function Menu(props:any){
    const [togle,setTogle] = useState<boolean>(true);
    const location = useLocation();
    const [actual,setActual] = useState<string>('/');
    useEffect(()=>{
        if (location.pathname != actual){
            setActual(location.pathname);
            setTogle(true);
        }
    })
    function mostrar(e){
        e.preventDefault();
    }
    return (
        <Fragment>
            <nav className={(!togle ? `${style.menu}` : `${style.menu} ${style.cerrado}` )}>
                    <NavLink exact  to="/" activeClassName={style.activo}>Inicio</NavLink>
                    <NavLink to="/hacemos" activeClassName={style.activo}>Que Hacemos</NavLink>
                    <NavLink to="/proceso" activeClassName={style.activo}>Nuestro Proceso</Link>
                    <NavLink to="/portfolio" activeClassName={style.activo}>Portfolio</NavLink>
                    <NavLink to="/contacto" activeClassName={style.activo}>Contacto</NavLink>
            </nav>
            <div onMouseDown={()=> setTogle(togle => !togle)} className={(togle ? `${style.menuBoton}` : `${style.menuBoton} ${style.cerrado}`)} >
                <span></span>
                <span></span>
                <span></span>
            </div>
        </Fragment>
    );
}