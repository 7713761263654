import * as React from 'react';
import { Component } from 'react';
import { render } from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import Rutas  from './app/rutas';

import Menu from './app/componentes/menu';


class App extends Component<{},{}>{
  render() {
    return (
        <BrowserRouter>
            <Menu />
            <Rutas />
        </BrowserRouter>
    )
  }
}

render(<App />, document.getElementById('root'));
