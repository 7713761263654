import * as React from 'react';
import { Component } from 'react';

import style from './style.scss';


export default function Proceso(props:any){
    return (
        <div className={style.wrapperVista}>
            <h1>Proceso</h1>
            <p>
                EN CONSTRUCCION
            </p>
        </div>
    );
}