import * as React from 'react';
import { Component } from 'react';

import style from './style.scss';


export default function Hacemos(props:any){
    return (
        <div className={style.wrapperVista}>
            <h1>Cazelab</h1>
            <div className={style.contenedor}>
                <h2>Resolvemos sus problemas de manera divertida.</h2>
                <p>
                    EN CONSTRUCCION
                </p>
                <div>
                   
                </div>
            </div>
        </div>
    );
}