import * as React from 'react';
import { Switch, Route } from "react-router-dom";
import Home from './vistas/home';
import Hacemos from './vistas/hacemos';
import Portfolio from './vistas/portfolio';
import Contacto from './vistas/contacto';
import Proceso from './vistas/proceso/index';


export default function Rutas(props:any){
    return (
        <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/hacemos" component={Hacemos} />
            <Route exact path="/portfolio" component={Portfolio} />
            <Route exact path="/contacto" component={Contacto} />
            <Route exact path="/proceso" component={Proceso} />
        </Switch>
    );
}