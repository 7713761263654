import * as React from 'react';
import { Component } from 'react';

import style from './style.scss';


export default function Contacto(props:any){
    return (
        <div className={style.wrapperVista}>
            <h1>Contacto</h1>
            <p>EN CONSTRUCCION</p>
        </div>
    );
}