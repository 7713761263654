import * as React from 'react';
import { Component } from 'react';

import style from './style.scss';


export default function Home(props:any){
    return (
        <div className={style.wrapper} id="home">
            <h1>CAZELAB.</h1>
            <p>Soluciones Multimediales.</p>
            <p>En Construccion.</p>
        </div>
    );
}